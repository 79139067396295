import Vue from 'vue';
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import VueRouter from 'vue-router';
import App from './App.vue';

Vue.config.productionTip = false
Vue.use(ElementUI);
Vue.use(VueRouter);
const router = new VueRouter({
  routes: [
    // ... 定义路由
  ]
});


new Vue({
  router,
  render: h => h(App),
}).$mount('#app')


