<template>
  <div id="app">
    <div style="width: 400px;margin: 0 auto;">
      <el-upload
          v-loading="loading"
          :element-loading-text="progresss"
          :http-request="refunc"
          :on-success="handleSuccess"
          class="upload-demo"
          action=""
          :on-progress="handleProgress"
          :before-upload="beforeUpload"
          :on-preview="handlePreview"
          :on-remove="handleRemove"
          multiple
          :limit="300"
          :on-exceed="handleExceed"
          :file-list="fileList">
        <el-button size="small" type="primary">点击上传</el-button>
        <div slot="tip" class="el-upload__tip">请上传完成后再关闭此页面</div>

      </el-upload>


    </div>

  </div>
</template>
<script>
import axios from 'axios';
import * as qiniu from 'qiniu-js';
import { Message } from 'element-ui';
export default {
  data() {
    return {
      loading:false,
      progresss:0,
      qiniuUploadUrl: '', // 这个URL可以是任意值，因为我们不会真正用它来上传
      uploadToken: null, // 这将是你的上传token
      formid:'',
      uploadurl:'',
      fileList: [
        // {name: 'food.jpeg', url: 'https://fuss10.elemecdn.com/3/63/4e7f3a15429bfda99bce42a18cdd1jpeg.jpeg?imageMogr2/thumbnail/360x360/format/webp/quality/100'},
        // {name: 'food2.jpeg', url: 'https://fuss10.elemecdn.com/3/63/4e7f3a15429bfda99bce42a18cdd1jpeg.jpeg?imageMogr2/thumbnail/360x360/format/webp/quality/100'}
      ]
    };
  },
  created() {
    let formidd = this.getparams(window.location.href)['formid']
    this.formid = formidd
    this.uploadurl = 'https://upthink.pgrlantu.top/index.php/index/index/getDataList?formid='+this.formid
    // console.log(this.formid)
    this.fetchUserData();
  },
  mounted(){

  },
  methods: {
    refunc(options){
      options.onSuccess();
    },
    getparams(url) {
      let  dz_url = url.split('#')[0];                //获取#/之前的字符串
      let  cs = dz_url.split('?')[1];                //获取?之后的参数字符串
      let  cs_arr = cs.split('&');                    //参数字符串分割为数组
      let params = {};
      // 遍历数组，将键值对保存到params对象中
      cs_arr.forEach(pair => {
        let keyValue = pair.split('=');
        params[keyValue[0]] = keyValue[1];
      });
      return params
    },
    beforeUpload(file) {
      let that = this
      file.status = 'success'
      that.$set(file,'status','success')
      console.log(file,'上传之前file')
      this.progresss = 0
      // 在外部定义变量
      const key = (parseInt(Math.random() * 100)) + file.name; // 可以根据业务自定义key的命名
      const putExtra = { fname: key };
      const config = { region: qiniu.region.z2 };
      // this.uploadToken = this.uploadToken; // 获取上传token的逻辑
      let fmid = this.formid

      const observable = qiniu.upload(file, key, this.uploadToken, putExtra, config);
      observable.subscribe({
        next(res) {
          // 可以在这里添加上传进度条更新的逻辑
          that.loading = true
          that.progresss = "上传进度" + parseInt(res.total.percent) + "%"
        },
        error(err) {
          console.error('upload error:', err);
        },
        complete(res) {
          const userData = {
            formid: fmid,
            name: res.key
          };
          axios.get('https://upthink.pgrlantu.top/index.php/index/index/uploadmysql',{params:userData}).then((res)=>{
            console.log(res.data.code )

            if (res.data.code == 0){
              Message(res.data.msg)
              that.loading = false

            }
            // location.reload()
            that.loading = false
          })
          //
        }
      });
      // 阻止element的默认上传行为
      return true;
    },

    async fetchUserData() {

      let resss = await axios.get('https://upthink.pgrlantu.top/index.php/index/index/getddList?formid='+this.formid)
      let gettoken = await axios.get('https://upthink.pgrlantu.top/index.php/index/index/gettoken?formid='+this.formid)
      this.fileList = resss.data
      console.log(resss.data)
      this.uploadToken = gettoken.data.token
    },
    download(file){

      console.log(file,'11')
    },
    handleRemove(file ) {
      // deletmysql
      axios.get('https://upthink.pgrlantu.top/index.php/index/index/deletmysql?id='+file.id).then((res)=>{
        console.log(res)
        if(res.data ==1){
          this.$message({
            type: 'success',
            message: '删除成功!'
          });
        }
      })

      console.log(file,'22' );
    },
    handlePreview(file) {
      //http://sfs3h28u8.hn-bkt.clouddn.com//images/dist.zip
      window.open(file.url, '_blank');
      // let a = document.createElement("a")
      // a.href = file.url
      // a.download = file.name
      // a.click()
      // console.log(file,'333');
    },
    handleProgress(event, file, fileList) {
      console.log('process',event, file, fileList)
      // event参数是一个包含percent属性的对象，percent属性是上传进度的百分比
      // this.progress = this.progresss;
      // const index = fileList.findIndex(item => item.uid === file.uid);
      // if (index !== -1) {
      //   fileList[index].percentage = Math.floor((event.loaded / event.total) * 100);
      // }
    },
    handleExceed( ) {
    },
    handleSuccess(response, file) {
      // 成功处理逻辑
      console.log('Upload success:', response, file);
    },


  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}
</style>
